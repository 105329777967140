<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 597 122"
    :width="width"
  >
    <path
      class="a"
      d="M458.5619,118.7744c-4.713,0-11.4273-3.1554-14.1791-7.012l-19.1121-26.716-19.1122,26.716c-2.7517,3.8566-9.456,7.012-14.179,7.012H366.7634L408.08,61.005,366.7635,3.2356H392.67c4.713,0,11.1471,3.7264,13.8989,7.573l77.2191,107.9658Z"
    />
    <path
      class="a"
      d="M31.16,73.2561c4.4128,15.8974,16.8708,21.7975,32.8109,21.7074,7.1045,0,14.1891-1.6128,20.0128-6.01,2.7617-2.0735,9.3359-3.3858,13.7787-.8214q7.885,4.5678,15.79,9.1157C101.5446,114.6574,84.2336,121.89,63.24,121.97,26.687,122,0,97.598,0,61.005Q0,34.89,17.0809,17.45T60.9388,0Q86.325,0,102.8255,17.56t16.51,43.4448a69.911,69.911,0,0,1-1.1608,12.2511Zm-.46-23.11H89.557q-2.5015-11.8-10.5067-17.56a29.9964,29.9964,0,0,0-18.1115-5.78q-12.0076,0-19.8526,6.12T30.7,50.1463"
    />
    <path
      class="a"
      d="M195.9948,0q18.912,0,31.27,12.7119t12.3478,35.12v70.9421h-16.01a13.7762,13.7762,0,0,1-13.7287-13.7536V51.5287q0-11.55-6.234-17.68T187.019,27.7277q-11.5373,0-18.4717,7.1624t-6.9244,21.487v62.3974h-16.01a13.7763,13.7763,0,0,1-13.7287-13.7537V3.2356h15.84a14.0152,14.0152,0,0,1,13.8889,12.9022h0Q172.44,0,195.9948,0"
    />
    <path
      class="a"
      d="M579.2187,104.33Q561.4473,122,535.8311,122t-43.2776-17.67q-17.6613-17.69-17.6612-43.3246t17.6612-43.3245Q510.2048,0,535.8311,0t43.3876,17.6805Q597,35.3508,597,61.005T579.2187,104.33M513.5569,83.8744q8.8855,9.0155,22.2742,9.0155t22.3842-9.0155q9.0057-9.0156,9.0057-22.8694t-9.0057-22.8794Q549.21,29.11,535.8311,29.11t-22.2742,9.0155q-8.8857,9.0156-8.8857,22.8794t8.8857,22.8694"
    />
    <path
      class="a"
      d="M283.8507,73.2561c4.4128,15.8974,16.8307,21.7975,32.8109,21.7074,7.1045,0,14.189-1.6128,20.0127-6.01,2.7618-2.0735,9.3359-3.3858,13.7788-.8214q7.8849,4.5678,15.79,9.1157c-12.0076,17.41-29.3186,24.6424-50.312,24.7225-36.5633,0-63.25-24.4019-63.25-60.9949q0-26.115,17.0809-43.555T313.63,0q25.3761,0,41.8867,17.56t16.51,43.4448a70.8375,70.8375,0,0,1-1.1507,12.2511Zm-.46-23.11h58.8274q-2.5316-11.8-10.5067-17.56a29.9964,29.9964,0,0,0-18.1115-5.78q-12.0077,0-19.8526,6.12T283.39,50.1463"
    />
    <path
      class="b"
      d="M425.5809,36.573S439.81,1.0017,483.5278,3.0052c0,0-17.281,42.7436-57.9469,33.5778"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: Number,
      default: 200,
    },
  },
};
</script>

<style>
.a {
  fill: #fff;
}
.b {
  fill: #4de0b2;
}
</style>
