<template>
  <div>
    <label
      class="block mb-4 mx-auto text-sm"
      :for="name"
      v-if="showLabel"
      v-html="label"
    />
    <input
      class="
        focus:shadow-sm focus:border-aqua-500 focus:outline-none
        block
        w-full
        text-sm
        border border-blue-500
        rounded-md
        py-2
        px-3
        transition
        ease-linear
        duration-200
      "
      :class="invalid && 'border-red-500'"
      :type="type"
      :name="name"
      :id="name"
      :min="min"
      :placeholder="placeholder"
      :value="value"
      :required="required"
      :disabled="disabled"
    />
  </div>
</template>

<script>
export default {
  name: 'InputBase',
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
    },
    placeholder: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [Number],
    },
  },
  methods: {},
};
</script>

<style scoped style="scss">
input {
  background: rgba(96, 221, 190, 0.1);
  background: linear-gradient(
    5deg,
    rgba(96, 221, 190, 0.1) 0%,
    rgba(96, 221, 190, 0) 100%
  );
}

input:disabled {
  @apply bg-gray-900 text-gray-200 border-gray-200 opacity-40;
}
</style>
