import { createRouter, createWebHistory } from 'vue-router';
// import HomeView from '@/views/HomeView.vue';
// import ResultEmissionView from '@/views/ResultEmissionView';
import RegisterView from '@/views/RegisterView';
import ValidationView from '@/views/ValidationView';
import ValidationCodeView from '@/views/ValidationCodeView';
import NotFound from '@/views/NotFound';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: RegisterView,
  },
  // {
  //   path: '/emission/',
  //   name: 'ResultEmission',
  //   component: ResultEmissionView,
  // },
  {
    path: '/register/',
    name: 'Register',
    component: RegisterView,
  },
  {
    path: '/validate/',
    name: 'Validation',
    component: ValidationView,
  },
  {
    path: '/validate/:email',
    name: 'Code Validation',
    component: ValidationCodeView,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
