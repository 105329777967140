<template>
  <div class="max-w-sm mx-auto w-full my-10">
    <h1 class="text-center mx-auto text-2xl">
      Page <span class="text-aqua-500">not found!</span>
    </h1>
    <h3 class="text-center mx-auto text-sm font-light mb-4 mt-2.5 opacity-80">
      Go back to the
      <router-link to="/" class="text-blue-400 underline">homepage</router-link
      >.
    </h3>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
