<template>
  <div>
    <form v-if="!validated" class="max-w-lg mx-auto w-full my-10" ref="registerForm" @submit.prevent="validateAccount">
      <h1 class="text-center mx-auto md:text-2xl">
        Validate <span class="text-aqua-500">{{ account }}</span>
      </h1>
      <h3 class="text-center mx-auto text-sm font-light mb-4 mt-2.5 opacity-80">
        You should have received a code via SMS or e-mail.
      </h3>
      <base-input
        class="w-full mt-5 opacity-80"
        name="validation_code"
        label="Validation code"
        type="text"
        placeholder="e.g., 805X234"
        :value="validationCode"
        :required="true"
        @change="(e) => (validationCode = e.target.value)"
      />

      <button
        class="bg-aqua-500 block text-sm focus:outline-none text-gray-800 sign-in-btn mt-10 mx-auto py-4 px-12 rounded-full transition duration-150 ease-in-out hover:bg-blue-400"
        type="submit"
      >
        Validate
      </button>
      <ResendValidationCode />
      <!-- error msg -->
      <p
        v-if="errorMsg"
        class="w-full border border-red-500 px-5 py-3 text-red-100 text-sm text-center rounded-lg mt-5"
      >
        {{ errorMsg }}
      </p>
    </form>
    <div class="max-w-sm mx-auto w-full my-10" v-else>
      <h1 class="text-center mx-auto text-2xl">Validation <span class="text-aqua-500">finished!</span></h1>
      <h3 class="text-center mx-auto text-sm font-light mb-4 mt-2.5 opacity-80">
        You can now
        <a :href="login" class="text-blue-400 underline">login to your account</a>.
      </h3>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import ResendValidationCode from "@/components/ResendValidationCode";

export default {
  components: {
    BaseInput,
    ResendValidationCode,
  },
  data() {
    return {
      validationCode: null,
      errorMsg: null,
      isLoading: false,
      validated: false,
    };
  },
  computed: {
    account() {
      return this.$route.params.email;
    },
    login() {
      return this.$store.state.referral !== ""
        ? `https://app.enexo.io/login?referral=${this.$store.state.referral}`
        : "https://app.enexo.io/login";
    },
  },
  methods: {
    validateAccount() {
      this.isLoading = true;

      const body = JSON.stringify({
        username: this.account,
        validationCode: this.validationCode.trim().toString(),
      });

      fetch("https://id-migration.enexo.io/auth/validate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      })
        .then((res) => res.text())
        .then((res) => {
          this.isLoading = false;

          if (res !== "OK") {
            console.log(res);
            throw JSON.parse(res);
          }

          this.validated = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorMsg = err.message;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
