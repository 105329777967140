import { createApp } from 'vue';
import { createStore } from 'vuex';
import App from './App.vue';
import router from './router';
import './assets/tailwind.css';
import './assets/main.scss';

const store = createStore({
  state() {
    return {
      referral: '',
    };
  },
  mutations: {
    SET_REFERRAL(state, content) {
      state.referral = content;
    },
    RESET_REFERRAL(state) {
      state.referral = '';
    },
  },
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount('#app');
