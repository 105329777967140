<template>
  <div class="switch-wrapper cursor-pointer rounded-full relative shadow-sm">
    <input
      :id="name"
      type="checkbox"
      :name="name"
      :checked="value"
      class="
        focus:outline-none focus:shadow-md
        checkbox
        w-4
        h-4
        rounded-full
        bg-white
        absolute
        m-1
        shadow-sm
        appearance-none
        cursor-pointer
      "
    />
    <label
      :for="name"
      class="
        toggle-label
        block
        w-12
        h-6
        overflow-hidden
        rounded-full
        border-2 border-blue-500
        cursor-pointer
        transition-colors
        duration-200
        ease-linear
      "
    ></label>
  </div>
</template>

<script>
export default {
  name: 'SwitchBase',
  props: {
    name: {
      type: String,
      required: false,
    },
    value: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.switch-wrapper {
  width: 48px;
}

.checkbox:checked {
  @apply right-0 bg-blue-500;
}

.checkbox:focus + .toggle-label {
  @apply border-blue-200 shadow-sm;
}

.checkbox:checked + .toggle-label {
  @apply border-blue-500;
}

.checkbox:checked:focus + .toggle-label {
  @apply border-green-400 shadow-sm;
}
</style>
