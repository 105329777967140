<template>
  <form class="max-w-sm mx-auto w-full my-10" ref="registerForm" @submit.prevent="register">
    <h1 v-if="!invite.token" class="text-center mx-auto text-2xl">Sign <span class="text-aqua-500">up.</span></h1>
    <h1 v-else class="text-center mx-auto text-2xl">Complete your <span class="text-aqua-500">invite.</span></h1>
    <h3 class="text-center mx-auto text-sm font-light mb-4 mt-2.5">Enter your details below.</h3>
    <div class="flex col">
      <base-input
        class="w-full opacity-80"
        name="firstName"
        label="First name"
        type="text"
        placeholder="e.g., John"
        :value="firstName"
        :required="true"
        @change="(e) => (firstName = e.target.value)"
        :invalid="v$.firstName.$dirty && v$.firstName.$invalid"
      />

      <base-input
        class="w-full opacity-80 ml-4"
        name="lastName"
        label="Last name"
        :type="false"
        placeholder="e.g., Chuckle"
        :value="lastName"
        :required="true"
        @change="(e) => (lastName = e.target.value)"
        :invalid="v$.lastName.$dirty && v$.lastName.$invalid"
      />
    </div>
    <base-input
      class="w-full mt-5 opacity-80"
      name="email"
      label="Email address"
      type="email"
      placeholder="e.g., johnchuckle@email.com"
      :disabled="invite.email"
      :value="email"
      :required="true"
      @change="(e) => (email = e.target.value)"
      :invalid="v$.email.$dirty && v$.email.$invalid"
    />
    <base-input
      class="w-full mt-5 opacity-80"
      name="password"
      label="Password"
      type="password"
      placeholder="at least 6 characters"
      :value="password"
      :required="true"
      @change="(e) => (password = e.target.value)"
      :invalid="v$.password.$dirty && v$.password.$invalid"
    />
    <base-input
      class="w-full mt-5 opacity-80"
      name="password_confirm"
      label="Confirm password"
      type="password"
      placeholder="must match password field"
      :value="passwordConfirm"
      :required="true"
      @change="(e) => (passwordConfirm = e.target.value)"
      :invalid="v$.passwordConfirm.$dirty && v$.passwordConfirm.$invalid"
    />
    <div class="flex items-center mt-6 justify-center">
      <base-switch @change="confirmationMethodHandler" :name="'sms_confirmation'" :value="isSMSconfirm" />
      <p class="text-sm opacity-80 ml-4">Validate by SMS <span>(email by default)</span></p>
    </div>
    <base-input
      class="w-full mt-5 opacity-80"
      v-if="isSMSconfirm"
      name="phone"
      label="Phone number"
      type="phone"
      placeholder="e.g., 07308xxxxxx"
      :value="phone"
      :required="true"
      @change="(e) => (phone = e.target.value)"
      :invalid="v$.isSMSconfirm && v$.phone.$dirty && v$.phone.$invalid"
    />
    <div class="w-full flex justify-center mt-5">
      <VueRecaptcha
        class="mx-auto"
        ref="recaptcha"
        sitekey="6Lf9fToeAAAAAMvpwsIw1ItsUUEfFT62b3OORXXt"
        @verify="recaptchaVerified = true"
        @expired="recaptchaVerified = false"
        theme="dark"
      />
    </div>
    <button
      class="bg-aqua-500 block text-sm focus:outline-none text-gray-800 sign-in-btn mt-10 mx-auto py-4 px-12 rounded-full transition duration-150 ease-in-out hover:bg-blue-400"
      type="submit"
      :disabled="isLoading"
    >
      Create account
    </button>
    <!-- error msg -->
    <p v-if="errorMsg" class="w-full border border-red-500 px-5 py-3 text-red-100 text-sm text-center rounded-lg mt-5">
      {{ errorMsg }}
    </p>
    <div v-if="v$" class="mt-5">
      <p v-for="error of v$.$errors" :key="error.$uid">{{ error.$property }}: {{ error.$message }}</p>
    </div>
    <router-link to="/validate" class="text-sm mx-auto block text-center mt-4 opacity-90 text-blue-100 underline">
      Validate your account.
    </router-link>
    <a
      href="https://app.enexo.io/"
      class="text-sm mx-auto block text-center mt-4 opacity-90 text-blue-100 underline"
      target="_blank"
    >
      Already registered?
    </a>
    <p class="text-gray-400 opacity-60 text-center text-xs mt-16">
      By clicking “Create account” above, you acknowledge that you have read, understood, and agree to Enexo's

      <a
        class="underline text-blue-400"
        href="https://app.enexo.io/terms-and-conditions"
        target="_blank"
        rel="noopener noreferrer"
        >Terms & Conditions</a
      >
      and
      <a
        class="underline text-blue-400"
        href="https://app.enexo.io/acceptable-use-policy"
        target="_blank"
        rel="noopener noreferrer"
        >Acceptable Use Policy</a
      >, and Softcat's
      <a
        class="underline text-blue-400"
        href="https://www.softcat.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
        >Privacy Policy</a
      >.
    </p>
  </form>
</template>

<script>
  import BaseInput from '@/components/BaseInput';
  import BaseSwitch from '@/components/BaseSwitch';
  import { VueRecaptcha } from 'vue-recaptcha';
  import useVuelidate from '@vuelidate/core';
  import { required, email, maxLength, sameAs, minLength } from '@vuelidate/validators';

  export default {
    data: () => ({
      invite: {
        token: null,
        email: null,
      },
      recaptchaVerified: false,
      lastName: null,
      firstName: null,
      email: null,
      password: null,
      passwordConfirm: null,
      isSMSconfirm: false,
      phone: null,
      isLoading: false,
      errorMsg: null,
    }),
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        firstName: {
          required,
          maxLength: maxLength(36),
        },
        lastName: {
          required,
          maxLength: maxLength(36),
        },
        email: {
          required,
          email,
          maxLength: maxLength(48),
        },
        password: { required, minLenth: minLength(8) },
        passwordConfirm: { required, sameAsPassword: sameAs(this.password) },
      };
    },
    mounted() {
      if (this.$route.query && this.$route.query.invite) {
        const decodeInvite = atob(this.$route.query.invite);
        if (decodeInvite.includes(':')) {
          this.invite.token = decodeInvite.split(':')[0];
          this.invite.email = decodeInvite.split(':')[1];
          this.email = decodeInvite.split(':')[1];
        }
      }
      if (this.$route.query && this.$route.query.referral) {
        this.$store.commit('SET_REFERRAL', this.$route.query.referral);
      }
    },
    methods: {
      register() {
        this.v$.$touch();

        if (this.v$.$invalid) return (this.erorrMsg = 'Unable to process request');

        this.errorMsg = null;

        const body = JSON.stringify({
          first_name: this.firstName,
          last_name: this.lastName,
          username: this.email,
          password: this.password,
          validation_method: this.isSMSconfirm ? 'SMS' : 'email',
          tel: this.isSMSconfirm ? this.phone : 'null',
          invite_token: this.invite.token || null,
        });
        this.isLoading = true;
        fetch('https://id-migration.enexo.io/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body,
        })
          .then((res) => res.text())
          .then((res) => {
            this.isLoading = false;

            if (res !== 'OK') {
              console.log(res);
              throw JSON.parse(res);
            }

            this.$refs.registerForm.reset();
            this.$router.push('/validate/' + this.email);
          })
          .catch((err) => {
            console.log(err);
            this.errorMsg = err.message;
          });
      },
      verifyMethod(el) {
        console.log('verify');
        console.log(el);
      },
      confirmationMethodHandler(e) {
        this.isSMSconfirm = e.target.checked;
      },
    },
    components: {
      BaseInput,
      BaseSwitch,
      VueRecaptcha,
    },
  };
</script>

<style lang="scss">
  .sign-in-btn {
    background: linear-gradient(120deg, #3b9ae8 20%, #60ddbe 110%);
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);

    &:hover,
    &:focus {
      background: linear-gradient(120deg, #358ed6 20%, #53c996 110%);
    }
  }

  .btn-secondary {
    box-shadow: 0 0 0 1px inset #fff;

    &:hover,
    &:focus {
      background: #fff;
    }
  }
</style>
