<template>
  <div>
    <form
      class="max-w-sm mx-auto w-full my-10"
      @submit.prevent="goToValidation"
    >
      <h1 class="text-center mx-auto text-2xl">
        Validate your <span class="text-aqua-500">account.</span>
      </h1>
      <h3 class="text-center mx-auto text-sm font-light mb-4 mt-2.5 opacity-80">
        Please tell us which account you are trying to validate
      </h3>
      <base-input
        class="w-full mt-5 opacity-80"
        name="email"
        label="Email address"
        type="email"
        placeholder="e.g., johnchuckle@email.com"
        :value="email"
        :required="true"
        @change="(e) => (email = e.target.value)"
      />
      <button
        class="
          bg-aqua-500
          block
          text-sm
          focus:outline-none
          text-gray-800
          sign-in-btn
          mt-10
          mx-auto
          py-4
          px-12
          rounded-full
          transition
          duration-150
          ease-in-out
          hover:bg-blue-400
        "
        type="submit"
      >
        Go to validation
      </button>
    </form>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput';

export default {
  components: {
    BaseInput,
  },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    goToValidation() {
      this.$router.push({ path: `/validate/${this.email}` });
    },
  },
};
</script>

<style lang="scss" scoped></style>
